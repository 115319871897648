import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiClientService } from '../../../domain/services/apiClientService';
import { dateService } from '../../../domain/services/dateService';
import { getDataSubdirectory } from '../../../domain/utils/contentUtils';
import { ContentHtml } from '../ContentHtml';
import { ParagraphWrapper } from '../ParagraphWrapper';

const PromoParagraphInner = props => {
  const { content } = props;

  const [promoContent, setPromoContent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const dataSubDir = getDataSubdirectory(content.id);
      const url = `/ajax/promo/${dataSubDir}${content.id}.json`;
      const { response } = await apiClientService.getJson(url);
      setPromoContent(response);
    };

    fetchData();
  }, []);

  const isWithinDateRange = dateService.isWithinDateRange(
    Math.floor(new Date().getTime() / 1000),
    promoContent?.data?.content?.mainContent?.entity?.startDate?.timestamp,
    promoContent?.data?.content?.mainContent?.entity?.endDate?.timestamp
  );

  const mustShowComponent =
    promoContent &&
    promoContent.data &&
    promoContent.data.content &&
    promoContent.data.content.isPublished &&
    isWithinDateRange;

  return mustShowComponent ? (
    <ContentHtml data={promoContent.data.content.mainContent.entity} />
  ) : (
    <div />
  );
};

PromoParagraphInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export const PromoParagraph = ParagraphWrapper(PromoParagraphInner);
